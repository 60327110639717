.privacy-content {
    display: grid;
    text-align: left;
    padding-left: 10vw;
    padding-right: 10vw;
}

.privacy-table {
    grid-column: 1;
    margin: auto;
    max-width: 50rem;
}

.lastUpdated {
    font-style: italic;
}

.pp-paragraph {
    margin: 0 0 30px;
}

.pp-paragraph li {
    margin: 0 0 5px;
    font-size: min(calc(16px + 0.8vw), 20px);
    line-height: 2rem;
}
