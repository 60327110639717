.terms-content {
    display: grid;
    text-align: left;
    padding-left: 10vw;
    padding-right: 10vw;
}

.terms-table {
    grid-column: 1;
    margin: auto;
    max-width: 50rem;
}

.lastUpdated {
    font-style: italic;
}

.terms-content a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

.tc-paragraph {
    margin: 0 0 30px;
}
