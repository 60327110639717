.home-content {
    grid-area: content;
    display: flex;
    text-align: left;
    justify-content: center;
}

.home-content-containers {
    display: flex;
    padding: 0 8vw;
}

.home-content-info {
    margin: auto 2vw;
}

.home-content h1 {
    margin-top: 5vh;
}

.home-content p {
    margin-top: 1rem;
    max-width: 45rem;
}

.app-showcase {
    margin-bottom: -70px;
    padding-top: 10px;
    margin-left: 4vw;
    z-index: 99;
}

@media (max-width: 767px) {
    .app-showcase {
        display: none;
    }
}

.appstore-badge {
    margin-top: 2rem;
    margin-bottom: 2rem;
    cursor: pointer;
    transform: translateZ(0);
    transition: transform 0.1s ease-out;
}

.appstore-badge:hover {
    transform: scale(1.02);
}
