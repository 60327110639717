@font-face {
  font-family: 'Material Iconic';
  font-display: swap;
  src: local('Material Iconic'), url(assets/fonts/Material-Design-Iconic-Font.woff2) format('woff2');
}

body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #0F4C81;
}

.wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-areas:
               "header"
               "content"
               "footer";
  background-color: #fff;
}

h1 {
  font-size: min(calc(22px + 0.8vw), 32px);
  font-weight: 600;
}

h2 {
  font-size: min(calc(20px + 0.8vw), 28px);
  font-weight: 600;
}

p {
  font-size: min(calc(16px + 0.8vw), 20px);
  line-height: 2rem;
}

@media (prefers-color-scheme: dark) {
  body {
    color: #eee;
    background-color: #1f1f1f;
  }

  .wrapper {
    color: #eee;
    background-color: #121212;
  }
}
