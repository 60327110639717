.footer {
    grid-area: footer;
    background-color: #0F4C81;
    color: white;
    min-height: 8rem;
    padding-top: 3rem;
}

@media (prefers-color-scheme: dark) {
    .footer {
        background: #1f1f1f;
    }
}

.footer-links a {
    border: 1px solid #fff;
    border-radius: 100%;
    color: #ffffff;
    display: inline-table;
    height: 46px;
    margin: 0 16px;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    width: 46px;
    text-decoration: none;
}

.footer-links a i {
    display: table-cell;
    vertical-align: middle;
}

.footer-links a:hover {
    background-color: #457B9D;
    border-color: #457B9D;
}

.footer-legal {
    font-size: 15px;
    margin-top: 18px;
    margin-bottom: 18px;
}

.footer-legal span {
    margin-left: 12px;
    margin-right: 12px;
}

.footer-legal a {
    color: #ffffff;
    display: inline-table;
    text-decoration: none;
}

.footer-copyright {
    font-weight: 500;
    font-size: 14px;
}

.material-iconic {
    font-family: "Material Iconic", serif;
    font-style: normal;
    font-size: 26px;
}

.instagram:before {
    content: '\f34f';
}

.facebook:before {
    content: '\f343';
}

.twitter:before {
    content: '\f360';
}
