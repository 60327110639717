.header {
    grid-area: header;
    display: flex;
    justify-content: flex-end;
    max-width: 90vw;
    padding-top: 5vh;
    font-size: min(calc(16px + 1.2vw), 26px);
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .header {
        margin-bottom: 10px;
    }
}

.header-nav > * {
    margin-left: 2rem;
}

.nav-link {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

.fade-in-nav-link {
    animation: fade 0.9s ease-in;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
}
